import { SubmissionViewProps } from "../../../models";

export function SubmissionRow(props: SubmissionViewProps) {
  return (
    <div {...props} class={`flex flex-col gap-10px ${props.class ?? ""}`}>
      <div class="flex justify-between text-16px m-20px cursor-pointer">
        <div class="flex gap-20px">
          <p>{props.name}</p>
          {props.region && (
            <>
              <p>|</p>
              <p>{props.region}</p>
            </>
          )}
          {props.title && (
            <>
              <p>|</p>
              <p>{props.title}</p>
            </>
          )}
        </div>
        <p>{props.date}</p>
      </div>
      <hr class="w-full h-2px bg-[#BDD2EA]" />
    </div>
  );
}
